<template>
  <Page title="Absenzenwesen" color="info">
    <v-toolbar flat class="mb-4">
      <TermPicker
        outlined
        text
        v-model="term"
        :range="[-3, 1]"
        v-if="!$route.path.includes('pending')"
      />

      <v-spacer />
    </v-toolbar>
    <v-btn
      color="success"
      fab
      fixed
      bottom
      right
      :to="{ name: 'JustificationStudentEdit', params: { justificationId: 0 } }"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
    <Student :id="$_profilePerson.id" :term="term" />
  </Page>
</template>
<script>
import { defineComponent } from "vue";
import TermPicker from "common/components/TermPicker.vue";
import Student from "./Student.vue";

export default defineComponent({
  components: {
    TermPicker,
    Student,
  },

  data() {
    return {
      items: [],
      term: null,
    };
  },
});
</script>
